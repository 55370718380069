<template>
  <v-app>
    <v-app-bar
      app
      color="#ffac43"
      dark
    >
      <div class="text-h6">
        SignBot Command Generator
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://gist.github.com/chrislo27/0dfda7768ddb4c191faf4215d7e03009"
        target="_blank"
        text
      >
        <span class="mr-2">Documentation</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <SignBot/>
    </v-main>
  </v-app>
</template>

<script>
import SignBot from './components/SignBot';

export default {
  name: 'App',

  components: {
    SignBot,
  },

  data: () => ({
    //
  }),
};
</script>
