<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                    <v-card-title>
                        Destination Sign Generator
                    </v-card-title>
                    <v-card-text>
                        <v-checkbox v-model="showPRSection" label="Include PR Message"></v-checkbox>
                        <v-checkbox v-model="showSecondFrame" label="Include Second Frame"></v-checkbox>

                        <div class="text--accent-1">Sign Size</div>
                        <br />
                        <v-row>
                            <v-col :cols="4">
                                <v-text-field
                                        v-model="signWidth"
                                        label="Sign Width"
                                        type="number"
                                        outlined
                                >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="4">
                                <v-text-field
                                        v-model="signHeight"
                                        label="Sign Height"
                                        type="number"
                                        outlined
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider><br />
                        <div class="text--accent-1">Destination Sign</div>
                        <br />
                        <v-row>
                            <v-col :cols="4">
                                <v-text-field
                                        v-model="routeText"
                                        label="Route Number"
                                        placeholder="Enter Route Number Text"
                                        outlined
                                >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="8">
                                <v-text-field
                                        v-model="firstFrameText"
                                        label="Sign Text"
                                        placeholder="Enter Destination Text"
                                        outlined
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-select
                                v-model="font"
                                label="Destination Font"
                                :items="fonts"
                                outlined
                        >
                        </v-select>
                        <div v-if="showSecondFrame">
                            <v-divider></v-divider><br />
                            <div class="text--accent-1">Second Frame</div>
                            <br />
                            <v-text-field
                                    v-model="secondFrameText"
                                    label="Second Frame Text"
                                    placeholder="Enter Destination Text"
                                    outlined
                            >
                            </v-text-field>
                            <v-select
                                    v-model="secondFrameFont"
                                    label="Second Frame Font"
                                    :items="fonts"
                                    outlined
                            >
                            </v-select>
                        </div>
                        <div v-if="showPRSection">
                            <v-divider></v-divider><br />
                            <div class="text--accent-1">PR Message</div>
                            <br />
                            <v-text-field
                                    v-model="prText"
                                    label="PR Message Text"
                                    placeholder="Enter PR Message"
                                    outlined
                            >
                            </v-text-field>
                            <v-select
                                    v-model="prFont"
                                    label="PR Message Font"
                                    :items="fonts"
                                    outlined
                            >
                            </v-select>
                        </div>
                        <div class="text--accent-1">Result</div><br />
                        <v-textarea
                                filled
                                readonly
                                v-model="compiledBotCommand"
                                label="Bot command"
                                disabled
                        ></v-textarea>
                        <div v-if="!this.valid" class="red--text pb-2">
                           {{ error }}
                        </div>
                        <div class="text-center">
                            <v-btn
                                    outlined
                                    :disabled="!valid"
                                    @click="copyBotCommand"
                            >
                                Copy to clipboard
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'SignBot',

        data: () => ({
            fonts: ["5", "5d", "6", "6d", "7", "7d", "8", "8d", "9", "9d", "10d", "11d", "12d", "13d", "14d", "15d", "16d", "16t", "22t", "24q", "f16", "balios16"],
            routeText: "480",
            firstFrameText: "Bridgeport Stn",
            secondFrameText: "Express",
            prText: "Sorry, Bus Full",
            signWidth: 160,
            signHeight: 16,
            font: "12d",
            prFont: "12d",
            secondFrameFont: "12d",
            showPRSection: false,
            showSecondFrame: false
        }),

        computed: {
            error(){
                if(this.firstFrameText === ""){
                    return "Please enter some sign text!"
                }
                if(this.showPRSection && this.prText === ""){
                    return "Please enter some sign PR text!"
                }
                if(this.showSecondFrame && this.secondFrameText === ""){
                    return "Please enter some text for the second frame!"
                }
                if(this.signWidth > 500 || this.signWidth <= 10){
                    return "Invalid sign width!"
                }
                if(this.signHeight > 1000 || this.signHeight <= 4){
                    return "Invalid sign height!"
                }
                return ""
            },
            valid() {
                return this.error === ""
            },
            compiledBotCommand(){
                const prefix = "%%dsign"
                const routeTag = this.routeText !== "" ? `!route ${this.routeText}` : ""
                const secondFrameTag = this.showSecondFrame ? ` !f ${this.secondFrameText} !font ${this.secondFrameFont}` : ""
                const prTag = this.showPRSection ? ` !pr !f ${this.prText} !font ${this.prFont}` : ""

                if (this.valid){
                    return `${prefix} !size ${this.signWidth} ${this.signHeight} !dest ${routeTag} !f ${this.firstFrameText} !font ${this.font}${secondFrameTag}${prTag}`
                } else {
                    return ""
                }
            }
        },
        methods: {
            copyBotCommand(){
                this.$clipboard(this.compiledBotCommand)
            }
        }
    }
</script>
